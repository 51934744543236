/*.navbar{
    display: flex;
    justify-content: space-around;
    padding: 16px;
    box-shadow: 0 1px 3px -2px black;
}
.nav-menu{
    display: flex;
    align-items: center;
    list-style: none;
    gap: 50px;
    color: black;
    font-size: 26px;
    font-weight: 500;
}
.nav-menu li{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    cursor: pointer;
}
.nav-menu a{
    color: black;
}
.nav-menu hr{
    border: none;
    width: 80%;
    height: 3px;
    border-radius: 10px;
    background: #FF4141;
}
*/

.nav-logo{
    display: flex;
    align-items: center;
    gap: 10px;
}
.nav-logo img{
    width: 150px;
}
.nav-logo p{
    color: #171717;
    font-size: 38px;
    font-weight: 600;
}
